<template>
  <main class="orgs-landing-wrapper">
    <section v-if="!orgs.length" class="no-orgs-wrapper">
      <div class="message-wrapper">
        <p class="-h4">
          Join an organization to start using our features and connect with
          other professionals in your field.
        </p>
        <div class="message-btns-wrapper">
          <div class="message-btn">
            <p class="-ltb">Search</p>
          </div>

          <div class="message-btn">
            <p class="-ltb">Create</p>
          </div>
        </div>
      </div>
      <div class="svg-container">
        <ThumbnailNoOrg />
      </div>
    </section>
  </main>
</template>

<script>
import ThumbnailNoOrg from "../dynamicThumbnails/ThumbnailNoOrg.vue";
export default {
  components: { ThumbnailNoOrg },

  data() {
    return {
      orgs: [],
    };
  },
  created() {
    //api code will be above

    if (this.orgs.length === 1)
      this.$router.push({
        name: "ShowOrganization",
        params: { id: this.orgs[0].org_id },
      });
    else if (this.orgs.length > 1) this.$router.push({ name: "OrgsListing" });
    else console.log("none of the above");
  },
  methods: {
    gotoRoute() {
      this.$router.push({ name: "CreateOrganization" });
    },
  },
};
</script>

<style scoped>
.orgs-landing-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 8px;
  gap: 16px;
  position: relative;
  background: #273339;
  animation: fade-bg 1600ms ease;
  overflow: auto;
}
.orgs-landing-wrapper:before {
  content: "";
  position: absolute;
  top: 28%;
  width: calc(100% - 1rem);
  height: 320px;
  transform: skewY(-5deg);
  background: linear-gradient(to right, #b7f7e7, #9fd1ff, #bbb1f5);
  border-radius: 4px;
  animation: slide-from-left 800ms ease;
}
.no-orgs-wrapper {
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: 800ms ease-in-out;
}
.message-wrapper {
  position: relative;
  z-index: 1;
  width: 50%;
  display: flex;
  flex-flow: column;
  gap: 2rem;
  text-align: right;
}
.message-wrapper > p:first-child {
  font-family: "Inter-Black";
  letter-spacing: 1px;
  animation: slide-in-text 800ms ease-out;
}
.message-btns-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.message-btn {
  padding-inline: 2rem;
  padding-block: 8px;
  border-radius: 2rem;
  user-select: none;
  cursor: pointer;
  transition: 200ms ease;
  opacity: 0;
  animation: fade-in 800ms ease-out;
  animation-delay: 800ms;
  animation-fill-mode: forwards;
  background: var(--white1);
}
.message-btn:hover {
  transform: translateY(-4px);
  letter-spacing: 2px;
  background: var(--highlight);
  color: var(--white1);
}
.svg-container {
  position: relative;
  z-index: 1;
  min-width: 360px;
  width: 600px;
  border-radius: 50%;
  overflow: hidden;
  /* background-image: radial-gradient(white, red); */
}
@media screen and (max-width: 1365px) {
  .no-orgs-wrapper {
    flex-flow: column;
    justify-content: center;
    width: 100%;
    gap: 8px;
  }
  .orgs-landing-wrapper:before {
    display: none;
  }
  .message-wrapper {
    width: 100%;
    padding: 1rem;
    text-align: center;
  }
  .message-wrapper > p:first-child {
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, #b7f7e7, #9fd1ff, #bbb1f5);
  }
  .message-btns-wrapper {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  .orgs-wrapper:before {
    display: none;
  }
  .svg-container {
    width: clamp(360px, 50%, 520px);
  }
}
@media screen and (max-width: 1023px) {
  .orgs-wrapper {
    flex-flow: column;
    gap: 8px;
  }
  .child-route {
    width: 100%;
  }
}
@keyframes slide-from-left {
  0% {
    opacity: 0;
    transform: skewY(-5deg) translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: skewY(-5deg) translateX(0);
  }
}
@keyframes fade-bg {
  0% {
    background: var(--white1);
  }
  100% {
    background: #273339;
  }
}
</style>